import React from "react";
import { createRoot } from "react-dom/client";
import "./i18n";
import Main from "./Main";
import "./app.css"

const rootElement = document.getElementById("root");

createRoot(rootElement).render(
    <React.StrictMode>
        <Main />
    </React.StrictMode>
);