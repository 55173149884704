import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'jp',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          navigation: {
            whoweare: 'Who We Are',
            whatwedo: 'What We Do',
            ceomessage: 'CEO Messages',
            aboutus: 'About Us',
            ourvision: 'Our Vision',
            whyvic: 'Why Victory AM Japan',
            relatedcom: 'Related Companies:<br>Victory Securities',
            leadership: 'Leadership',
            assetmanagement: 'Asset Management',
            regulations: 'Regulations',
            investmentstra: 'Investment Strategies',
            cs: 'Contact Us',
            careers: 'Careers'
          },
          home: {
            title: 'Victory <br>Asset Management <br>Japan',
          },          
          whoweare: {
            title: 'Who We Are',
            ceo_message: 'CEO MESSAGE',
            message1: 'Welcome to Victory Asset Management Japan! This is Katerine Kou.',
            message2: 'Victory Securities (stock code: 8540.HK, “Victory Securities”), rooted in Hong Kong for over 50 years. We offer four primary business services: wealth management, asset management, virtual asset, and capital markets. Leveraging our successful presence in Hong Kong, Mainland China, and Singapore, we are confident that Victory Asset Management Japan (“Victory AM Japan”) will deliver exceptional services to Japanese investors.<br><br>In September 2023, Victory Securities is proud to present its subsidiary Victory AM Japan has been successfully granted the Type II Financial Instruments Business license from Japan Financial Services Agency. It is the first overseas company to obtain this license in Fukuoka City, Japan.<br><br>Victory Securities is taking proactive steps towards overseas expansion, simultaneously diversifying our product portfolio, and expanding the business footprint. Our team of experienced financial professional is committed to cultivating long-lasting client relationships and helping them achieve their investment goals through tailored solutions.',
            aboutus_title: 'About Us',
            aboutus1: 'Victory',
            aboutus2: 'Securities has expanded its operations by entering the Japanese market in 2023 with the establishment of its subsidiary, Victory Asset Management Japan in Fukuoka, Japan.<br><br>The establishment of Victory Asset Management Japan marks a significant milestone for Victory Securities, it is a new chapter in our business. Additionally, this development brings a new investment choice to investors in Japan.<br><br>To leverage our expertise from Hong Kong to Japan, our team of experienced financial professional offers customised solutions aimed at cultivating long-lasting client relationships.<br><br>With the Type-II Financial Instruments Business license, Victory AM Japan will provide a wide range of services, including intermediation and brokerage of buy and sell, solicit or privately placement of collective investment scheme equity.',
            why_victory: 'WHY VICTORY AM JAPAN',
            l_title: 'Bringing Together Local<br>Insight & Global Oversight',
            l_description: 'Our professional teams develop and share their intellectual capital globally. We have unique insights on investment and abundant research experience in different industries. Being global vision allows us to capture opportunities wherever they may arise.',
            m_title: 'Helping Investors Thrive',
            m_description: 'Helping investors thrive by capturing global opportunities. By offering expert insights and specialist investment strategies, we aim to provide investors with sustainable investment opportunities.',
            r_title: 'Invest Confidently with a Licensed Company in Japan',
            r_description: 'Victory AM Japan has successfully obtained Type II Financial Instruments Business license from Japan Financial Services Agency. Your funds protection and security are top of our mind.  We operate in a well-regulated sector of the financial services industry. Our operations are subject to a range of local regulations that can protect your funds.  ',
            leadership: 'Leadership',
            b_l_title: 'Katerine Kou<br> Chief Executive Officer',
            b_l_description: 'Ms. Kou has 30 years of experience in the securities industry.  She has led Victory Securities in planning different projects and promoting business development across Asia. Under her guidance, Victory Securities\' performance has maintained steady growth. Katerine obtained a Bachelor of Administrative Studies degree from the University of York in Toronto, Canada. Katerine is also approved by the Securities and Futures Commission ("SFC") in Hong Kong as the responsible officer for Type 1, Type 2, Type 4, and Type 9 regulated activities.',
            b_m_title: 'Kennix Chan<br> Director of Business Development',
            b_m_description: 'Mr. Chan graduated from the University of Nottingham with a Bachelor of Arts in Management and a Master of Finance from the Chinese University of Hong Kong. He manages Victory Securities and Victory Asset Management Japan\'s business development and operations, including legal affairs, compliance, settlement and clearing, IT, and risk management. He helped the company to develop new system software and applications, and under his leadership, the Group launched its first mobile trading application and many other internal systems used by the Group members. He is also approved by the Securities and Futures Commission ("SFC") in Hong Kong as the responsible officer for Type 1, Type 4, and Type 9 regulated activities.',
            b_r_title: 'Adam Zhou<br> Director of Sales Department',
            b_r_description: 'Mr. Zhou graduated from the Chinese University of Hong Kong with an MBA (Finance) and has over ten years of investment and finance experience. He is responsible for Asset Management, overseas Capital Market, and overall frontline business. He oversees Victory Securities and Victory Asset Management Japan’s expansion projects. He is currently responsible for developments across China, Singapore, and Japan to uncover new opportunities for Victory Group and its clients.',
          },
          whatwedo: {
            title: 'What<br> We Do',
            regulations: 'Regulations',
            reg_title1: 'Who are the Regulators?',
            reg_title2: 'Financial Services Agency, Japan',
            reg_description: 'The Type II Financial Instruments Business license is required to buy, sell, broker or handle the offering of illiquid securities such as collective investment scheme type funds or trust beneficiary right to investors residing in Japan.<br><br>With the Type II Financial Instruments Business license, Victory AM Japan will provide a wide range of services, including intermediation and brokerage of buy and, sell, solicit or privately placement of collective investment scheme equity.',
            reg_l1_title: 'Illiquid Security (e.g., collective investment scheme-equity, trust beneficiary right, membership right of a limited liability company, etc.)',
            reg_l2_title: 'Certain securities (e.g., Mortgage securities, foreign mortgage securities, etc.)',
            reg_r1_title: 'Buy and sell <br>Intermediation or brokerage of buy and sell <br>Handling of offerings, secondly-distribution or private placements',
            reg_r2_title: 'Public offering or private placement',
            reg_description2: 'Primary acts covered by Type II Financial Instruments Business as below',
            reg_desc2_l1: 'Illiquid Security',
            reg_desc2_l2: '(e.g., collective investment scheme-equity,<br>trust beneficiary right,<br>membership right of a limited liability company, etc.)',
            reg_desc2_l3: 'Buy and sell',
            reg_desc2_l4: 'Intermediation or brokerage of buy and sell',
            reg_desc2_l5: 'Handling of offerings, secondly-distribution or private placements',
            reg_desc2_r1: 'Certain Securities',
            reg_desc2_r2: '(e.g., Mortgage securities, foreign mortgage securities, etc.)',
            reg_desc2_r3: 'Public offering or<br> private placement',
            investment: 'Investment Strategies',
            l1_title: 'In-Depth Research',
            l1_description: 'To seek a long-term and sustainable return, we aim to profit from booming industries and related industrial growth. Therefore, we are looking for industries with inevitable growth and leading companies in these industries. Our experts analyze substantial data and information on the macro and micro factors of various sectors and asset classes, and we can better navigate market volatility to make the best investment decision.',
            l2_title: 'Asset Allocation <br>with Profession',
            l2_description: 'Victory’s asset management team closely follows the global macroeconomic dynamics and selects high-quality tracks with high prosperity and ample market growth space with a top-down concept. We establish valuation models based on a bottom-up approach to do the asset allocation by selecting a leading industry with stable performance growth and leading companies with healthy financial status.',
            r1_title: 'Global Vision',
            r1_description: 'Victory’s investment and research teams are very familiar with the international market. With the global perspective brought by studying abroad, they understand different countries’ national conditions and policies, besides the development cycles of various industries.',
            r2_title: 'Distinctive Insights',
            r2_description: 'Victory’s asset management team has unique insights on investment and abundant research experience in different industries. With in-depth research and distinctive insights, we can grasp the investment opportunities, bringing our clients the best-in-class investment performance. To become a lifelong trusted partner of global investors.',
          },
          customerservices: {
            title: 'Contact Us',
            description: 'Whatever query you have, wherever you are, and whenever you need to contact us, our support team are on hand to respond as soon as possible.',
            tel: 'TEL:',
            fax: 'FAX:',
            email: 'Email:',
            address_title: 'Mailing Address:',
            address: '〒 810-0001, Tenjin Daisan Building, 3rd Floor, <br>3-3-5 Tenjin, Chuo-ku, <br>Fukuoka, Japan',
          },
          mediacenter: {
            title: 'Media<br> Center',
          },
          careers: {
            title: 'Careers',
            description: 'If you are looking for a company you can grow with, this might be the right place for you.<br>Building meaningful and purposeful careers with the best talent in the market.',
            subtitle: 'CAREER OPPORTUNITIES',
            description2: 'If you are interested in taking up the following new challenges, please email your full resumes and expected salary to general_inquiry@victoryam.jp. All information received will be kept in strict confidence and only for employment-related purpose.',
            button_text: 'Send Now',
          },
          contact: {
            title: 'Mauris sem',
            content: 'Visit <a href=\'{{link}}\'>Example Website </a> for more information.'
          },
          footer: {
            company_name: 'Victory Asset Management Japan Co., Limited.',
            stock_code: '(Stock Code: 8540.HK)<br>Headquarter',
            copyright: 'Copyright © 2023 Victory Asset Management Japan Co., Limited. All rights reserved.',
            company_address: '〒 810-0001, Tenjin Daisan Building, 3rd Floor, 3-3-5 Tenjin, Chuo-ku, Fukuoka, Japan',
            bottom_left: 'Victory Asset Management Japan Co. Ltd. is a financial instruments firm registered under the Financial Services Agency (FSA) of Japan, with the registered number 115 (登錄番号：第115号)'
          }
        }
      },
      jp: {
        translation: {
          navigation: {
            whoweare: 'Victory AM Japan について',
            whatwedo: '事業範囲',
            ceomessage: 'CEOメッセージ',
            aboutus: '勝利證券グループについて',
            ourvision: 'ビジョン',
            whyvic: 'なぜ勝利AMジャパンなのか',
            relatedcom: 'Related Companies:<br>Victory Securities',
            leadership: 'リーダーシップ',
            assetmanagement: '資産運用',
            regulations: '規制',
            investmentstra: '投資戦略',
            cs: 'お問い合わせ',
            careers: '採用情報'
          },
          home: {
            title: 'アセットマネジメン<br>トジャパン株式会社',
          },
          whoweare: {
            title: '',
            ceo_message: 'CEOメッセージ',
            message1: '勝利アセットマネジメントジャパン株式会社の紹介ページへようこそ！ Katerine Kouと申します。',
            message2: 'Victory Securities（銘柄コード：8540.HK）（以下「勝利證券」といいます。）は、香港に根ざして50年以上が経ちます。当社は、資産管理、資産運用、金融仲介、資本市場の4つの主要事業サービスを提供しています。勝利アセットマネジメントジャパン株式会社（以下「勝利AMジャパン」といいます。）は、香港、中国本土、シンガポールでの実績を生かし、日本の投資家の皆様に卓越したサービスを提供できると確信しています。<br><br>勝利證券は、子会社である勝利アセットマネジメントジャパン株式会社（以下「勝利AMジャパン」といいます。）が、2023年9月に日本の金融庁より第二種金融商品取引業の登録を受けたことを発表いたします。勝利AMジャパンは外資系企業として初めての、日本の福岡市において第二種金融商品取引業の登録を受けた企業となります。<br><br>勝利證券は、海外展開に向けて前向きな一歩を踏み出し、同時に商品ポートフォリオを多様化し、事業拠点を拡大しています。当社の経験豊富な金融のプロフェッショナルチームが、お客様との長期的な信頼関係を築き、お客様のニーズに合ったソリューションを通じて、投資目標の達成をお手伝いします。',
            aboutus_title: '勝利證券グループについて',
            aboutus1: '勝利',
            aboutus2: '證券は2023年、福岡に子会社として勝利AMジャパンを設立し、日本市場に参入し事業を拡大しました。<br><br>勝利AMジャパンの設立は、勝利證券にとって重要な節目であり、当社の事業の新たな章の始まりとなります。<br>さらに、この展開は、日本の投資家に新たな投資の選択肢をもたらします。<br><br>香港で得た専門知識を日本で活用するため、当社の経験豊富な金融のプロフェッショナルチームが、<br>お客様との長期的な信頼関係を築くことを目的として、お客様に合わせたソリューションを提供いたします。<br><br>勝利AMジャパンは、第二種金融商品取引業の登録をもって、集団投資スキーム持分の売買の媒介及び取次ぎ、募集及び私募の取扱いを含む幅広いサービスを提供します。',
            why_victory: 'なぜ勝利AMジャパンなのか？',
            why_victory_description:'',
            // why_victory_description: '同社は、⽇本の投資家にベストな選択肢を提供するために、親会社のリソースやサポートをフルに活⽤します。Victoryが選ばれる理由同社の資産運⽤チームは、中国市場、⽶国市場、アジア市場など、国際投資エクスポージャーについて異なるスキルを持つ専⾨家で構成されています。これらの専⾨家は、熱意、勤勉さ、誠実さをもって資産運⽤にあたり、顧客や投資家に対して最善を提供するとともに、様々なリスク管理⼿法を⽤いて投資リスクを最⼩化します。',
            l_title: 'ローカルな知見とグローバルな監督体制を一つに',
            l_description: '当社のプロフェッショナルチームは、グローバルにその知的資本を開拓し、共有しています。当社は、投資に関する独自の見識と、様々な業界における豊富な分析経験を有しています。世界的な視野により、当社は世界中の投資機会を掴むことができます。',
            m_title: '投資家の成功を支援',
            m_description: '世界中への投資機会を投資家へ提供し、その成功を支援します。専門的な見識と専門的な投資戦略を提供することで、投資家へ持続的に投資機会を提供することを目指しています。',
            r_title: '日本において登録を受けた企業で安心の投資を',
            r_description: '勝利AMジャパンは、日本の金融庁より第二種金融商品取引業の登録を受けました。お客様の資金の保護と安全は当社の最優先事項です。当社は、金融サービス業界の中でも特に規制の厳しい分野で事業を展開しています。当社の事業は、お客様の資金を保護する様々な現地規制の対象となっています。',
            leadership: 'リーダーシップ',
            b_l_title: '最高経営責任者（CEO）<br>Katerine Kou',
            b_l_description: '高鵑(Katerine Kou)は、証券業界における30年の経験を持ちます。勝利證券を率いて、様々なプロジェクトを企画し、アジア全域での事業展開を推進しました。Katerine Kouの指導の下、勝利證券の業績は着実な成長を維持しています。また、Katerine Kouは、カナダのトロントに所在するヨーク大学にて行政学学士号を取得しています。<br>Katerine Kouはまた、香港の証券先物委員会（以下「SFC」といいます。）により、タイプ1、タイプ2、タイプ4、タイプ9の規制業務の責任者として認可されています。',
            b_m_title: '事業開発部長<br>Kennix Chan',
            b_m_description: '陳沛泉(Kennix Chan)は、ノッティンガム大学を卒業し（学士号（経営学）取得）、その後香港中文大学にて修士号（ファイナンス）を取得しています。<br>勝利證券及び勝利AMジャパンにおいては事業開発及び法務、コンプライアンス、決済・清算、IT、リスク管理を含む業務を統括しています。<br>また、Kennix Chanは、新たなシステム・ソフトウェアやアプリケーションの開発に貢献し、そのリーダーシップの下、勝利證券グループ初のモバイル取引アプリケーションや、勝利證券グループのメンバーが使用するその他多くの社内システムを立ち上げました。Kennix Chanはまた、SFCにより、タイプ1、タイプ4、タイプ9の規制業務の責任者として認可されています。',
            b_r_title: '営業部長<br>Adam Zhou',
            b_r_description: '周樂樂(Adam Zhou)は、香港中文大学においてMBA（ファイナンス）を取得しており、10年以上の投資・金融経験を持っています。<br>Adam Zhouは資産運用、海外資本市場、フロントライン業務全般を担当しており、勝利證券と勝利AMジャパンの事業拡大プロジェクトを監督する立場にあります。<br>Adam Zhouは現在、中国、シンガポール、日本市場を担当し、勝利證券グループとお客さまの新たな投資機会を開拓しています。',
          },
          whatwedo: {
            title: '事業範囲',
            investment: '投資戦略',
            regulations: '規制',
            reg_title1: '監督当局は？',
            reg_title2: '日本の金融庁',
            reg_description: '第二種金融商品取引業の登録は、日本に居住する投資家に対して、集団投資スキーム持分や信託受益権などの非流動性証券の売買の媒介・取次ぎ、募集及び私募の取扱い等を行うにあたって必要となります。<br><br>勝利AMジャパンは、第二種金融商品取引業の登録をもって、集団投資スキーム持分の売買、勧誘、私募の媒介及び取次ぎを含む幅広いサービスを提供します。',
            reg_l1_title: '非流動性証券（みなし有価証券）',
            reg_l2_title: '特定の証券<br>（例えば、抵当証券、外国抵当証券など）',
            reg_r1_title: '売買<br>売買の媒介や取次ぎ<br>募集、売出し、私募の取扱い',
            reg_r2_title: '公募や私募',
            reg_description2: '第二種金融商品取引業に該当する主な行為は以下のとおりです。',
            reg_desc2_l1: '非流動性証券（みなし有価証券）',
            reg_desc2_l2: '（例えば、集団投資スキーム持分、<br>信託受益権、合同会社の社員権など）',
            reg_desc2_l3: '売買 ',
            reg_desc2_l4: '売買の媒介や取次ぎ',
            reg_desc2_l5: '募集、売出し、私募の取扱い',
            reg_desc2_r1: '特定の証券',
            reg_desc2_r2: '（例えば、抵当証券、外国抵当証券など）',
            reg_desc2_r3: '公募や私募',
            l1_title: '徹底的なリサーチ',
            l1_description: '長期的かつ持続可能なリターンを追求するため、好況産業と関連産業の成長から利益を得ることを目指しています。そのため、当社は常に、成長することが見込まれる業界と、その業界をリードする企業を調査しています。当社の専門家は、様々なセクターや資産クラスのマクロ及びミクロの要因に関する膨大なデータや情報を分析し、変動の激しい市場において最適な投資判断を下すために、より良い指針を提供することができます。',
            l2_title: 'プロフェッショナルによるアセット・アロケーション',
            l2_description: '勝利證券グループの資産運用チームは、世界のマクロ経済の動態を注意深くモニタリングし、トップダウン・アプローチによって、高い成長可能性と豊富な市場機会における、有望な投資機会を選定しています。ボトムアップ・アプローチに基づくバリュエーション・モデルを構築し、業績が安定的に成長している業界や、財務状況が健全な有力企業を選定してアセット・アロケーションを行います。',
            r1_title: 'グローバル・ビジョン',
            r1_description: '勝利證券グループの投資・調査チームは国際市場に精通しています。国際市場における豊富な経験によってもたらされるグローバルな視点によって、チームメンバーは様々な国の国情や政策を理解し、これに加えて、様々な産業の発展サイクルをも理解しています。',
            r2_title: '際立った洞察力',
            r2_description: '勝利證券グループの資産運用チームは、投資に関する独自の見識と、様々な業界における豊富な調査経験を持っています。世界の投資家から生涯にわたって信頼されるパートナーとなるために、綿密なリサーチと際立った洞察力で投資機会を把握し、お客さまに業界最高水準のサービスをお届けします。',
          },
          customerservices: {
            title: 'お問い合わせ先',
            description: 'カスタマーサービス・一般的なお問合せ',
            tel: '電話番号:',
            fax: 'ファックス:',
            email: 'Eメールアドレス:',
            address_title: '',
            address: '〒 810-0001 福岡市中央区天神3丁目<br>3番5号天神大産ビル3階',
          },
          mediacenter: {
            title: 'メディア報道',
          },
          careers: {
            title: '採用情報',
            description: '共に成長できる会社をお探しなら、当社が最適かもしれません。<br>市場で最高の人材と共に、意義と目的のあるキャリアを築けます。',
            subtitle: '募集',
            description2: '勝利AMジャパンにおける新たな挑戦にご興味のある方は、履歴書と希望給与をEメールにて下記までお送りください。general_inquiry@victoryam.jp お預かりした一切の情報は、雇用に関する目的のみに使用し、厳に機密として管理いたします。',
            button_text: '今すぐ送信',
          },
          contact: {
            title: 'こんにちは',
            content: 'マエセナス・ヘンドレリット・ラオレット・オーグネック・モレスティ。 出産の際の社会的な問題や、深刻な問題、馬鹿げた <a href=\'{{link}}\'>問題</a>。'
          },
          footer: {
            company_name: '勝利アセットマネジメントジャパン株式会社',
            stock_code: '(証券コード: 8540.HK)<br>本社',
            copyright: 'Copyright © 2023 Victory Asset Management Japan Co., Limited. All rights reserved.',
            company_address: '〒 810-0001 福岡市中央区天神3丁目3番5号天神大産ビル3階',
            bottom_left: '勝利アセットマネジメントジャパン株式会社は、日本の金融庁に登録された金融商品取引業者（福岡財務支局長（金商）第115号）です。'
          }
        }
      }
    }
  });

export default i18n;
