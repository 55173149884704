import React from "react";
import { useTranslation, Trans } from 'react-i18next';
import MainBannerImage from "./assets/img/mc_main.png";
import Under_Maintenance from './assets/img/under_maintenance.png';

 
const MediaCenter = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="page">
      <main className="flex-grow relative">
        <div className="w-full bg-center bg-cover bg-no-repeat" style={{ backgroundImage: `url(${MainBannerImage})`, height: '500px' }}></div>
        <div className="absolute text-white font-normal font-['Myriad Pro'] uppercase top-40 left-10 text-5xl lg:text-8xl"><Trans i18nKey="mediacenter.title" /></div>
      </main>
      <div className="grid grid-cols-1 md:flex">
        <div className="flex flex-col items-center w-full">
          <div className="w-full mx-auto my-auto text-sky-900 text-4xl font-semibold font-['Myriad Pro'] uppercase">
            <div className="p-10"></div>
            <div className="p-5 text-center uppercase">Media Center</div>
            <div className="p-5"></div>
            <div className="flex">
              { i18n.language === 'jp' ? (
                <h1 className="flex mx-auto py-20" style={{ height: '500px' }}>準備中…</h1>
              ) : (
                <img className="mx-auto p-10" src={Under_Maintenance} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

 
export default MediaCenter;