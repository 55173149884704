import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
// import MainBannerImage1 from "./assets/img/wwa_main.png";
// import MainBannerImage2 from "./assets/img/wwa_main_hover.png";
import MainPageBannerImage_EN from './assets/img/MainPageBanner_EN.png';
import MainPageBannerImage_JP from './assets/img/MainPageBanner_JP.png';
import MainPageBannerImage_EN_M from './assets/img/MainPageBannerImage_EN_M.png';
import MainPageBannerImage_JP_M from './assets/img/MainPageBannerImage_JP_M.png';
import CEOImage from "./assets/img/ceo_image.png";
import VSLogoJapan2 from './assets/img/VS Logo_Japan_2.png';
import Rectangle7_en from './assets/img/Rectangle7_en.png';
import Rectangle7_jp from './assets/img/Rectangle7_jp.png';
import group8 from './assets/img/Group8.png';
import group9 from './assets/img/Group9.png';
import group12 from './assets/img/Group12.png';
import protrait1 from './assets/img/protrait1.png';
import protrait2 from './assets/img/protrait2.png';
import protrait3 from './assets/img/protrait3.png';
 
const WhoWeAre = () => {
  const { t, i18n } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
  }
  const handleMouseLeave = () => {
    setIsHovered(false);
  }
  return (
    <div className="page">
      <main className="flex-grow relative">
      {/* <div className={`w-full bg-center bg-cover bg-no-repeat`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ backgroundImage: `url(${isHovered ? MainBannerImage2 : MainBannerImage1})`, height: '500px' }}></div> */}
      { isMobile  ? (
        <div
        className={`w-full bg-center bg-cover bg-no-repeat`} style={{backgroundImage: `url(${isMobile && i18n.language === 'jp' ? MainPageBannerImage_JP_M : MainPageBannerImage_EN_M})`, 'min-height': '530px'}}></div>
      ) : (
        <div className={`w-full bg-center bg-cover bg-no-repeat `}  style={{ backgroundImage: `url(${i18n.language === 'en' ? MainPageBannerImage_EN : MainPageBannerImage_JP })`, height: '625px' }}></div>
      )}
        {/* <div className="absolute text-white font-normal font-['Myriad Pro'] uppercase top-20 left-10 text-5xl lg:text-8xl"><Trans i18nKey="home.title" /></div> */}
      </main>
      <div className="flex items-center py-10" id="whoerare">
        <div className="mx-auto">
          <div className="AboutUs text-justify text-sky-900 text-4xl font-semibold font-['Myriad Pro'] uppercase p-5"><Trans i18nKey="whoweare.title" /></div>
          {/* <div><img className="VsLogoJapan2 p-5 my-5 mx-auto" src={VSLogoJapan2} alt='VSLogo' /></div> */}
        </div>
      </div>
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 flex items-center " id="ceo">
        <img className="col-span-1 mx-10 mx-auto" src={CEOImage} alt="ceo" style={{height: '280px'}}/>
        <div className="col-span-1 md:col-span-2 p-5 max-w-802">
            <div className="CeoMessage text-justify text-sky-900 text-3xl font-semibold font-['Myriad Pro'] uppercase"><Trans i18nKey="whoweare.ceo_message" /></div>
            <div className="w-full text-justify">
              <span className="text-sky-900 font-bold font-['Myriad Pro'] leading-loose"><Trans i18nKey="whoweare.message1" /><br/><br/></span>
              <span className="text-sky-900 font-normal font-['Myriad Pro']"><Trans i18nKey="whoweare.message2" /></span>
            </div>
        </div>
      </div>
      <div className="p-5"></div>

      <div className="flex items-center" id="aboutus">
        <div className="mx-auto">
          <div className="AboutUs text-justify text-sky-900 text-4xl font-semibold font-['Myriad Pro'] uppercase p-5"><Trans i18nKey="whoweare.aboutus_title" /></div>
          {/* <div><img className="VsLogoJapan2 p-5 my-5 mx-auto" src={VSLogoJapan2} alt='VSLogo' /></div> */}
        </div>
      </div>
      <div className="container mx-auto text-center p-5 my-5 w-3/5">
        <span className="text-sky-900 text-5xl font-normal font-['Myriad Pro'] uppercase"><Trans i18nKey="whoweare.aboutus1" /></span>
        <span className="text-sky-900 font-normal font-['Myriad Pro']"><Trans i18nKey="whoweare.aboutus2" /></span>
      </div>
      <div className="p-5"></div>

      <div className="Vision container mx-auto justify-center flex items-center relative" id="ourvision">
        <img className="w-full z-0" src={i18n.language === 'jp' ? Rectangle7_jp : Rectangle7_en} alt="Rectangle7" style={{ width: '1111px'}}/>
      </div>
      <div className="p-10"></div>

      <div className="flex items-center" id="whyvictoryjapan">
      {i18n.language === 'jp' ? (
        <div className="mx-auto">
          <div className="AboutUs text-center text-sky-900 text-4xl font-semibold font-['Myriad Pro'] uppercase p-5 max-w-1280"><Trans i18nKey="whoweare.why_victory" /></div>
          {/* <div className="p-5 max-w-1280 text-justify text-sky-900 text-xl font-semibold font-['Myriad Pro']"><Trans i18nKey="whoweare.why_victory_description" /></div> */}
        </div>
      ) : (
        <div className="mx-auto">
        <div className="AboutUs mx-auto text-justify text-sky-900 text-4xl font-semibold font-['Myriad Pro'] uppercase p-5 max-w-1280"><Trans i18nKey="whoweare.why_victory" /></div>
      </div>
      )} 
      </div>
      <div className="p-10"></div>
      <div className="container mx-auto text-center grid md:grid-cols-3">
        <div className="col-span-1">
          <div className="min-h-550">
            <img src={group8} className="mx-auto p-5 "/>
            <div className="text-sky-900 text-2xl font-normal font-['Myriad Pro'] min-h-96"><Trans i18nKey="whoweare.l_title" /></div><br/>
            <div className="p-5 max-w-600 mx-auto text-sky-900 font-normal font-['Myriad Pro']"><Trans i18nKey="whoweare.l_description" /></div>
          </div>
        </div>
        <div className="col-span-1">
          <div className="min-h-550">
            <img src={group9} className="mx-auto p-5 h-170" />
            <div className="text-sky-900 text-2xl font-normal font-['Myriad Pro'] min-h-96"><Trans i18nKey="whoweare.m_title" /></div><br/>
            <div className="p-5 max-w-600 mx-auto text-sky-900 font-normal font-['Myriad Pro']"><Trans i18nKey="whoweare.m_description" /></div>
          </div>
        </div>
        <div className="col-span-1">
          <div className="min-h-550">
            <img src={group12} className="mx-auto p-5 h-170"/>
            <div className="text-sky-900 text-2xl font-normal font-['Myriad Pro'] min-h-96"><Trans i18nKey="whoweare.r_title" /></div><br/>
            <div className="p-5 max-w-600 mx-auto text-sky-900 font-normal font-['Myriad Pro']"><Trans i18nKey="whoweare.r_description" /></div>
          </div>
        </div>
      </div>
      <div className="p-5"></div>

      <div className="flex items-center" id="leadership">
        <div className="mx-auto">
          <div className="AboutUs text-justify text-sky-900 text-4xl font-semibold font-['Myriad Pro'] uppercase p-5"><Trans i18nKey="whoweare.leadership" /></div>
        </div>
      </div>
      <div className="text-center p-5 my-5 grid md:grid-cols-3 container mx-auto max-w-1300" >
        <div className="col-span-1">
          <img src={CEOImage} alt="protrait1" className="mx-auto p-5" style={{height: '280px'}}/>
          <div className="text-sky-900 text-2xl font-normal font-['Myriad Pro'] min-h-96"><Trans i18nKey="whoweare.b_l_title" /></div><br/>
          <div className="p-10 max-w-600 mx-auto text-left text-sky-900 font-normal font-['Myriad Pro'] text-justify"><Trans i18nKey="whoweare.b_l_description" /></div>
        </div>
        <div className="col-span-1">
          <img src={protrait2} alt="protrait2" className="mx-auto p-5" style={{height: '280px', weight: '431px'}}/>
          <div className="text-sky-900 text-2xl font-normal font-['Myriad Pro'] min-h-96"><Trans i18nKey="whoweare.b_m_title" /></div><br/>
          <div className="p-10 max-w-600 mx-auto text-left text-sky-900 font-normal font-['Myriad Pro'] text-justify"><Trans i18nKey="whoweare.b_m_description" /></div>
        </div>
        <div className="col-span-1">
          <img src={protrait3} alt="protrait3" className="mx-auto p-5" style={{height: '280px'}}/>
          <div className="text-sky-900 text-2xl font-normal font-['Myriad Pro'] min-h-96"><Trans i18nKey="whoweare.b_r_title" /></div><br/>
          <div className="p-10 max-w-600 mx-auto text-left text-sky-900 font-normal font-['Myriad Pro'] text-justify"><Trans i18nKey="whoweare.b_r_description" /></div>
        </div>
      </div>
      <div className="p-10"></div>
      <div className="table flex mx-auto">
        <table className="border-collapse border border-black">
          <thead>
            <tr>
              <td className="border border-black px-2">加入協会</td>
              <td className="border border-black px-2">一般社団法人第二種金融商品取引業協会</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-black px-2">認定投資者保護団体</td>
              <td className="border border-black px-2">ありません</td>
            </tr>
            <tr>
              <td className="border border-black px-2 leading-8">指定紛争解決機関又は苦<br />情処理措置及び紛争解決<br />措置の内容</td>
              <td className="border border-black px-2 leading-8">特定非営利活動法人証券・金融商品あっせん相談<br />センター<br />電話：0120-64-5005<br />受付時間：土日祝日を除く9:00～17:00</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="p-10"></div>
    </div>
  );
}

 
export default WhoWeAre;