import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import Header from "./header";
import Footer from "./footer";
import LanguageSwitcher from "./LanguageSwitcher";

function Main() {
  const { t } = useTranslation();

  useEffect(() => {
    // Set the desired language here (e.g., 'jp' for Japanese)
    const desiredLanguage = 'jp';
    
    // Set the "i18nextLng" value to the desired language
    i18n.changeLanguage(desiredLanguage);
  }, []);
  return (
    <div className="flex flex-col min-h-screen bg-white">
      <LanguageSwitcher/>
      <Header />
      <Footer />
    </div>
  );
}

export default Main;