import React from "react";
import { useTranslation, Trans } from 'react-i18next';
import MainBannerImage from "./assets/img/wwd_main.png";
import Rectangle11_en from './assets/img/Rectangle11_en.png';
import Rectangle11_jp from './assets/img/Rectangle11_jp.png';
import group8 from './assets/img/Group.png';
import group22 from './assets/img/Group22.png';
import group10 from './assets/img/group10.png';
import group11 from './assets/img/group11.png';
import p1 from './assets/img/Investor.png';
import p2 from './assets/img/Analysis.png';
// import group28 from './assets/img/Group28.png';
// import group29 from './assets/img/Group29.png';
// import group30 from './assets/img/Group30.png';
// import group31 from './assets/img/Group31.png';
 
const WhatWeDo = () => {
  const { i18n } = useTranslation();
  return (
    <div className="page">
      <main className="flex-grow relative">
        <div className="w-full bg-center bg-cover bg-no-repeat" style={{ backgroundImage: `url(${MainBannerImage})`, height: '500px' }}></div>
        <div className="absolute text-white font-normal font-['Myriad Pro'] uppercase top-40 left-10 text-5xl lg:text-8xl"><Trans i18nKey="whatwedo.title" /></div>
      </main>

      <div className="Vision container mx-auto justify-center flex items-center relative" id="assetmanagement">
        <img className="w-full z-0" src={i18n.language === 'jp' ? Rectangle11_jp : Rectangle11_en } alt="Rectangle11" style={{width: '1111px'}}/>
      </div>
      <div className="p-5"></div>

      <div className="flex items-center" id="regulations">
        <div className="mx-auto w-3/5">
          <div className="AboutUs mx-auto text-center text-sky-900 text-4xl font-semibold font-['Myriad Pro'] uppercase p-5 max-w-1280"><Trans i18nKey="whatwedo.regulations" /></div>
          <div className="text-sky-900 font-normal text-2xl font-['Myriad Pro'] p-5 text-center"><Trans i18nKey="whatwedo.reg_title1" /></div>
          <div className="text-sky-900 font-normal font-['Myriad Pro'] text-center underline font-semibold">
            <a href={`https://www.fsa.go.jp/${i18n.language === 'jp' ? 'index.html' : 'en' }`}>
              <Trans i18nKey="whatwedo.reg_title2" />
            </a>
          </div>
          <div className="container mx-auto text-center p-5 my-5">
            <span className="text-sky-900 font-normal font-['Myriad Pro']"><Trans i18nKey="whatwedo.reg_description" /></span>
          </div>
        </div>
      </div>
      <div className="container mx-auto text-center p-5 my-5">
        <span className="text-sky-900 font-normal text-2xl font-['Myriad Pro'] p-5 text-center"><Trans i18nKey="whatwedo.reg_description2" /></span>
      </div>
      <div className="container mx-auto text-center p-5 my-5 grid md:grid-cols-2">
        <div className="col-span-1 pb-10">
          <div className={i18n.language === 'jp' ? 'min-h-260' : 'min-h-290'}>
            <img src={p1} className="mx-auto p-5 h-170" alt="p1" />
            <div className="text-sky-900 text-2xl font-normal font-['Myriad Pro']"><Trans i18nKey="whatwedo.reg_desc2_l1" /></div>
            <div className="text-sky-900 text-xl font-normal font-['Myriad Pro']"><Trans i18nKey="whatwedo.reg_desc2_l2" /></div>
          </div>
          <div className="p-3"></div>
          <ul className="text-sky-900 font-normal font-['Myriad Pro'] mx-auto text-left list-disc pd-left-40">
            <li><Trans i18nKey="whatwedo.reg_desc2_l3" /></li>
            <li><Trans i18nKey="whatwedo.reg_desc2_l4" /></li>
            <li><Trans i18nKey="whatwedo.reg_desc2_l5" /></li>
          </ul>
        </div>
        <div className="col-span-1 pb-10">
          <div className={i18n.language === 'jp' ? 'min-h-260' : 'min-h-290'}>
            <img src={p2} className="mx-auto p-5 h-170" alt="p2"/>
            <div className="text-sky-900 text-2xl font-normal font-['Myriad Pro']"><Trans i18nKey="whatwedo.reg_desc2_r1" /></div>
            <div className="text-sky-900 text-xl font-normal font-['Myriad Pro']"><Trans i18nKey="whatwedo.reg_desc2_r2" /></div>
          </div>
          <div className="p-3"></div>
          <ul className="text-sky-900 font-normal font-['Myriad Pro'] mx-auto text-left list-disc pd-left-45">
            <li><Trans i18nKey="whatwedo.reg_desc2_r3" /></li>
          </ul>
        </div>
      </div>

      {/* old version */}
      {/* <div className="container mx-auto text-center p-5 my-5 grid md:grid-cols-2">
        <div className="col-span-1">
          <div className="min-h-400">
            <div className="h-200"><img src={group28} className="mx-auto p-5 h-170"/></div>
            <div className="text-sky-900 font-normal font-['Myriad Pro'] min-h-65 p-5"><Trans i18nKey="whatwedo.reg_l1_title" /></div><br/>
          </div>
          <div className="p-5"></div>
          <div className="min-h-400">
            <div className="h-200"><img src={group31} className="mx-auto p-5 h-170"/></div>
            <div className="text-sky-900 font-normal font-['Myriad Pro'] min-h-65 p-5"><Trans i18nKey="whatwedo.reg_l2_title" /></div><br/>
          </div>
        </div>
        <div className="col-span-1">
          <div className="min-h-400">
            <div className="h-200"><img src={group30} className="mx-auto p-5 h-170"/></div>
            <div className="text-sky-900 font-normal font-['Myriad Pro'] min-h-65 p-5"><Trans i18nKey="whatwedo.reg_r1_title" /></div><br/>
          </div>
          <div className="p-5"></div>
          <div className="min-h-400">
            <div className="h-200"><img src={group29} className="mx-auto p-5 h-170"/></div>
            <div className="text-sky-900 font-normal font-['Myriad Pro'] min-h-65 p-5"><Trans i18nKey="whatwedo.reg_r2_title" /></div><br/>
          </div>
        </div>
      </div> */}
      {/* new version but not going to show right now */}
      {/* <div className="p-5"></div>
      <div class="relative flex flex-col mx-auto items-center justify-center p-5 space-y-6 lg:flex-row lg:space-y-0 lg:space-x-4 w-3/5 left-5p">
        <img src={group28} style={{width: '100px'}}/>
        <span className="w-3/4">
            <span className="flex mx-auto text-sky-900 font-normal font-['Myriad Pro'] items-center"><Trans i18nKey="whatwedo.reg_l1_title" /></span>
        </span>
        <img src={group30} style={{width: '100px'}}/>
        <span className="w-3/4">
            <span className="flex mx-auto text-sky-900 font-normal font-['Myriad Pro'] items-center"><Trans i18nKey="whatwedo.reg_r1_title" /></span>
        </span>
      </div>
      <div className="p-5"></div>
      <div class="relative flex flex-col mx-auto items-center justify-center p-5 space-y-6 lg:flex-row lg:space-y-0 lg:space-x-4 w-3/5 left-5p">
        <img src={group31} style={{width: '100px'}}/>
        <span className="w-3/4">
            <span className="flex mx-auto text-sky-900 font-normal font-['Myriad Pro'] items-center"><Trans i18nKey="whatwedo.reg_l2_title" /></span>
        </span>
        <img src={group29} style={{width: '100px'}}/>
        <span className="w-3/4">
            <span className="flex mx-auto text-sky-900 font-normal font-['Myriad Pro'] items-center"><Trans i18nKey="whatwedo.reg_r2_title" /></span>
        </span>
      </div> */}
      <div className="p-5"></div>
      <div className="flex items-center" id="strategies">
        <div className="mx-auto">
          <div className="AboutUs mx-auto text-center text-sky-900 text-4xl font-semibold font-['Myriad Pro'] uppercase p-5 max-w-1280"><Trans i18nKey="whatwedo.investment" /></div>
        </div>
      </div>
      <div className="p-5"></div>
      <div className="container mx-auto text-center p-5 my-5 grid md:grid-cols-2">
        <div className="col-span-1">
          <div className="min-h-600">
            <img src={group8} className="mx-auto p-5 h-170" alt="group8" />
            <div className="text-sky-900 text-2xl font-normal font-['Myriad Pro'] min-h-65"><Trans i18nKey="whatwedo.l1_title" /></div><br/>
            <div className="p-5 max-w-600 mx-auto text-sky-900 font-normal font-['Myriad Pro']"><Trans i18nKey="whatwedo.l1_description" /></div>
          </div>
          {/* <div className="p-5"></div> */}
          <div className="min-h-600">
            <img src={group11} className="mx-auto p-5 h-170" alt="group11" />
            <div className="text-sky-900 text-2xl font-normal font-['Myriad Pro'] min-h-65"><Trans i18nKey="whatwedo.l2_title" /></div><br/>
            <div className="p-5 max-w-600 mx-auto text-sky-900 font-normal font-['Myriad Pro']"><Trans i18nKey="whatwedo.l2_description" /></div>
          </div>
        </div>
        <div className="col-span-1">
          <div className="min-h-600">
            <img src={group22} className="mx-auto p-5 h-170" alt="group22" />
            <div className="text-sky-900 text-2xl font-normal font-['Myriad Pro'] min-h-65"><Trans i18nKey="whatwedo.r1_title" /></div><br/>
            <div className="p-5 max-w-600 mx-auto text-sky-900 font-normal font-['Myriad Pro']"><Trans i18nKey="whatwedo.r1_description" /></div>
          </div>
          {/* <div className="p-5"></div> */}
          <div className="min-h-600">
            <img src={group10} className="mx-auto p-5 h-170" alt="group10" />
            <div className="text-sky-900 text-2xl font-normal font-['Myriad Pro'] min-h-65"><Trans i18nKey="whatwedo.r2_title" /></div><br/>
            <div className="p-5 max-w-600 mx-auto text-sky-900 font-normal font-['Myriad Pro']"><Trans i18nKey="whatwedo.r2_description" /></div>
          </div>
        </div>
      </div>

      {/* <div className="p-10"></div> */}
    </div>
  );
}

 
export default WhatWeDo;
