import React from "react";
import { useTranslation, Trans } from 'react-i18next';
import MainBannerImage from "./assets/img/cs_main.png";
import Rectangle12 from './assets/img/Rectangle12.png';
import phone from './assets/img/phone.png';
import fax from './assets/img/fax.png';
import printer from './assets/img/printer.png';
import email from './assets/img/email.png';
 
const CustomerServices = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="page">
      <main className="flex-grow relative">
        <div className="w-full bg-center bg-cover bg-no-repeat" style={{ backgroundImage: `url(${MainBannerImage})`, height: '500px' }}></div>
        <div className="absolute text-white font-normal font-['Myriad Pro'] uppercase top-40 left-10 text-5xl lg:text-8xl"><Trans i18nKey="customerservices.title" /></div>
      </main>
      <div className="grid grid-cols-1 md:flex">
        {/* <img className="Rectangle12" src={Rectangle12} /> */}
        <div className="flex flex-col items-center w-full">
          <div className="w-full mx-auto my-auto text-sky-900 text-4xl font-semibold font-['Myriad Pro']" id="contactus">
            <div className="p-5"></div>
            <div className="p-5 text-center"> {i18n.language === 'jp' ? '' : 'CONTACT US' } </div>
            <div className="text-center text-xl"><Trans i18nKey="customerservices.description" /></div>
            <div className="p-5"></div>
            <div className="grid md:grid-cols-4">
              <div className="text-center text-2xl">
                <div className=""><img className="mx-auto p-2 mb-2" src={phone} alt="phone_icon"/></div>
                <div className=""><Trans i18nKey="customerservices.tel" /></div>
                <div className="text-xl">092-791-9109</div>
              </div>
              <div className="text-center text-2xl">
                <div className=""><img className="mx-auto p-2 mb-2" src={printer} alt="phone_icon" width={95} height={95}/></div>
                <div className=""><Trans i18nKey="customerservices.fax" /></div>
                <div className="text-xl">092-401-1688</div>
              </div>
              <div className="text-center text-2xl">
                <div className=""><img className="mx-auto p-2" src={email} alt="email_icon"/></div>
                <div className=""><Trans i18nKey="customerservices.email" /></div>
                <div className="text-xl">general_inquiry@victoryam.jp</div>
              </div>
              <div className="text-center text-2xl">
                <div className=""><img className="mx-auto p-2 mb-1" src={fax} alt="fax_icon"/></div>
                <div className=""><Trans i18nKey="customerservices.address_title" /></div>
                <div className="text-xl"><Trans i18nKey="customerservices.address" /></div>
              </div>
              <div className="p-10"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

 
export default CustomerServices;