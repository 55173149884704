import React from "react";
import { useTranslation, Trans } from 'react-i18next';
import footer_logo_1 from './assets/img/footer_logo_1.png';
import footer_logo_2 from './assets/img/footer_logo_2.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

const Footer = () => {
    const { t, i18n } = useTranslation();
    return (
        <footer className="bg-sky-900 py-2">
            <div className="grid md:grid-cols-2 gap-4 p-4 text-gray-300">
                <div className="text-center">
                    {/* <div className="m-5 font-bold text-3xl">Contact Us</div> */}
                    <div className="grid grid-cols-1 md:grid-cols-2">
                        <div className="text-center py-5">
                            <div><a href="https://victorysec.com.hk/en"><img className="mx-auto pb-2" src={footer_logo_2} alt="logo2" style={{width: '260px'}} /></a></div>
                        </div>
                        <div className="footer_bottom_2">
                            <div className="pb-5 font-bold text-xl"><Trans i18nKey="footer.company_name" /></div>
                            <ul>
                                <li className="flex items-center">
                                    <FontAwesomeIcon icon="fa-solid fa-location-dot" />&nbsp;<div className="text-left"><Trans i18nKey="footer.company_address" /></div>
                                </li>
                                <li className="flex items-center">
                                    <FontAwesomeIcon icon="fa-solid fa-phone" /><div>&nbsp;092-791-9109</div>
                                </li>
                                <li className="flex items-center">
                                    <FontAwesomeIcon icon="fa-solid fa-fax" /><div>&nbsp;092-401-1688</div>
                                </li>
                                <li className="flex items-center uppercase">
                                    <FontAwesomeIcon icon="fa-solid fa-envelope" /><a href="mailto:general_inquiry@victoryam.jp">&nbsp;general_inquiry@victoryam.jp</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3">
                    <div></div>
                    <div></div>
                    <div className="text-left">
                        <div>
                            <a href="/WhoWeAre" className="justify-center uppercase hover:text-white hover:bg-sky-900 leading-10">
                                <Trans i18nKey="navigation.whoweare" />
                            </a>                        
                        </div>
                        <div>
                            <a href="/WhatWeDo" className="justify-center uppercase hover:text-white hover:bg-sky-900 leading-10">
                                <Trans i18nKey="navigation.whatwedo" />
                            </a>
                        </div>
                        <div>
                            <a href="/CustomerServices" className="justify-center uppercase hover:text-white hover:bg-sky-900 leading-10">
                                <Trans i18nKey="navigation.cs" />
                            </a>
                        </div>
                        <div>
                            <a href="/Careers" className="justify-center uppercase hover:text-white hover:bg-sky-900 leading-10">
                                <Trans i18nKey="navigation.careers" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-20 py-5">
                <div className="text-gray-300">商号等：第二種金融商品取引業    福岡財務支局長(金商)　第115号　加入協会：一般社団法人　第二種金融商品取引業協会<br/><a href="https://financialcity.fukuoka.jp/en/index.html">MEMBER OF TEAM FUKUOKA</a></div>
            </div>
            <div className="p-10"></div>
            <div class="mx-auto w-95p border-line"></div>
            <div className="text-center pt-10 pb-5">
                <div>
                    <a href="https://victorysec.com.hk/en">
                        <img className="mx-auto pb-2" src={footer_logo_1} alt="logo1" />
                    </a>
                </div>
                {/* <div class="text-gray-300"><Trans i18nKey="footer.stock_code" /></div> */}
            </div>
            <div className="flex justify-between">
                {/* <div className="text-white text-xs font-normal font-['Myriad Pro'] text-left pl-5"><Trans i18nKey="footer.bottom_left" /></div> */}
                <div className="text-white text-xs font-normal uppercase font-['Myriad Pro'] mx-auto pb-5" style={{width: '610px'}}><Trans i18nKey="footer.copyright" /></div>
            </div>
        </footer>
    )
}

export default Footer;