import React from "react";
import { useTranslation } from "react-i18next";

const langs = {
  en: { nameDisplay: "English" },
  jp: { nameDisplay: "Japanese" },
};

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  return (
    <div className="text-right md-mx-20">
      {Object.keys(langs).map((lang) => (
        <button
          key={lang}
          className={`px-2 font-medium text-xl ${i18n.resolvedLanguage === lang ? 'text-blue-700' : 'text-gray-700'}`}
          type="submit"
          onClick={() => i18n.changeLanguage(lang)}
        >
          {langs[lang].nameDisplay}
        </button>
      ))}
    </div>
  );
};

export default LanguageSwitcher;