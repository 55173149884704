import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { motion, AnimatePresence } from 'framer-motion';
// import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { HashLink } from 'react-router-hash-link';
import { useTranslation, Trans } from 'react-i18next';
import Home from "./Home";
import WhoWeAre from "./WhoWeAre";
import WhatWeDo from "./WhatWeDo";
import MediaCenter from "./MediaCenter";
import CustomerServices from "./CustomerServices";
import Careers from "./Careers";
import VSLogoJapan from './assets/img/VS Logo_Japan.png';

const Header = () => {
    const { t, i18n } = useTranslation();
    const [showDropdown1, setShowDropdown1] = useState(false);
    const [showDropdown2, setShowDropdown2] = useState(false);
    const [showDropdown3, setShowDropdown3] = useState(false);
    const [showDropdown4, setShowDropdown4] = useState(false);
    const [showDropdown5, setShowDropdown5] = useState(false);
    const [navbar, setNavbar] = useState(false);
  
    const toggleDropdown1 = () => {
      setShowDropdown1(!showDropdown1);
    };
    const toggleDropdown2 = () => {
      setShowDropdown2(!showDropdown2);
    };
    const toggleDropdown3 = () => {
      setShowDropdown3(!showDropdown3);
    };
    const toggleDropdown4 = () => {
      setShowDropdown4(!showDropdown4);
    };
    const toggleDropdown5 = () => {
      setShowDropdown5(!showDropdown5);
    };
    return (
        <Router>
            <header className="bg-white">
            <div className="flex items-center justify-center h-16">
            <Link to="/"><img src={VSLogoJapan} className="" alt="logo" /></Link>
            </div>
            <nav className="hidden md:block">
                <ul className="flex justify-center">
                    <li onMouseEnter={toggleDropdown1} onMouseLeave={toggleDropdown1} className="relative">
                        <Link to="/WhoWeAre" className="flex w-48 basis-0 justify-center text-sky-900 text-xs font-bold font-['Myriad Pro'] uppercase px-3 py-3 text-blue-900 hover:text-white hover:bg-sky-900">
                        <Trans i18nKey="navigation.whoweare" />
                        </Link>
                        {showDropdown1 && (
                        <ul className="absolute top-full left-0 z-10 bg-white">
                            <li>
                                <HashLink smooth to="/WhoWeAre#ceo" className="flex w-48 justify-center text-sky-900 text-xs font-bold font-['Myriad Pro'] px-3 py-3 text-blue-900 hover:text-white hover:bg-sky-900"><Trans i18nKey="navigation.ceomessage" /></HashLink>
                            </li>
                            <li>
                                <HashLink smooth to="/WhoWeAre#aboutus" className="flex w-48 justify-center text-sky-900 text-xs font-bold font-['Myriad Pro'] px-3 py-3 text-blue-900 hover:text-white hover:bg-sky-900"><Trans i18nKey="navigation.aboutus" /></HashLink>
                            </li>
                            <li>
                                <HashLink smooth to="/WhoWeAre#ourvision" className="flex w-48 justify-center text-sky-900 text-xs font-bold font-['Myriad Pro'] px-3 py-3 text-blue-900 hover:text-white hover:bg-sky-900"><Trans i18nKey="navigation.ourvision" /></HashLink>
                            </li>
                            <li>
                                <HashLink smooth to="/WhoWeAre#whyvictoryjapan" className="flex w-48 justify-center text-sky-900 text-xs font-bold font-['Myriad Pro'] px-3 py-3 text-blue-900 hover:text-white hover:bg-sky-900"><Trans i18nKey="navigation.whyvic" /></HashLink>
                            </li>
                            {/* <li>
                                <a className="flex w-48 justify-center text-sky-900 text-xs font-bold font-['Myriad Pro'] px-3 py-3 text-blue-900 hover:text-white hover:bg-sky-900" href="https://victorysec.com.hk/en">Parent Company:<br>Victory Securities</a>
                            </li> */}
                            <li>
                            <HashLink smooth to="/WhoWeAre#leadership" className="flex w-48 justify-center text-sky-900 text-xs font-bold font-['Myriad Pro'] px-3 py-3 text-blue-900 hover:text-white hover:bg-sky-900"><Trans i18nKey="navigation.leadership" /></HashLink>
                            </li>
                        </ul>
                        )}
                    </li>
                    <li onMouseEnter={toggleDropdown2} onMouseLeave={toggleDropdown2} className="relative">
                        <Link to="/WhatWeDo" className="flex w-48 basis-0 justify-center text-sky-900 text-xs font-bold font-['Myriad Pro'] uppercase px-3 py-3 text-blue-900 hover:text-white hover:bg-sky-900">
                            <Trans i18nKey="navigation.whatwedo" />
                        </Link>
                        {showDropdown2 && (
                        <ul className="absolute top-full left-0 z-10 bg-white">
                            <li>
                                <HashLink smooth to="/WhatWeDo#assetmanagement" className="flex w-48 justify-center text-sky-900 text-xs font-bold font-['Myriad Pro'] px-3 py-3 text-blue-900 hover:text-white hover:bg-sky-900"><Trans i18nKey="navigation.assetmanagement" /></HashLink>
                            </li>
                            <li>
                                <HashLink smooth to="/WhatWeDo#regulations" className="flex w-48 justify-center text-sky-900 text-xs font-bold font-['Myriad Pro'] px-3 py-3 text-blue-900 hover:text-white hover:bg-sky-900"><Trans i18nKey="navigation.regulations" /></HashLink>
                            </li>
                            <li>
                                <HashLink smooth to="/WhatWeDo#strategies" className="flex w-48 justify-center text-sky-900 text-xs font-bold font-['Myriad Pro'] px-3 py-3 text-blue-900 hover:text-white hover:bg-sky-900"><Trans i18nKey="navigation.investmentstra" /></HashLink>
                            </li>
                        </ul>
                        )}
                    </li>
                    <li onMouseEnter={toggleDropdown3} onMouseLeave={toggleDropdown3} className="relative">
                        <Link to="/CustomerServices" className="flex w-48 basis-0 justify-center text-sky-900 text-xs font-bold font-['Myriad Pro'] px-3 py-3 text-blue-900 hover:text-white hover:bg-sky-900 uppercase">
                            <Trans i18nKey="navigation.cs" />
                        </Link>
                        {/* {showDropdown3 && (
                        <ul className="absolute top-full left-0 z-10 bg-white">
                            <li>
                                <HashLink smooth to="/CustomerServices#contactus"  className="flex w-48 justify-center text-sky-900 text-xs font-bold font-['Myriad Pro'] px-3 py-3 text-blue-900 hover:text-white hover:bg-sky-900">Contact Us</HashLink>
                            </li>
                        </ul>
                        )} */}
                    </li>
                    {/* <li onMouseEnter={toggleDropdown4} onMouseLeave={toggleDropdown4} className="relative">
                        <Link to="/MediaCenter" className="flex w-48 basis-0 justify-center text-sky-900 text-xs font-bold font-['Myriad Pro'] px-3 py-3 text-blue-900 hover:text-white hover:bg-sky-900">
                            MEDIA CENTER
                        </Link>
                        {showDropdown4 && (
                        <ul className="absolute top-full left-0 z-10 bg-white">
                            <li>
                                <Link to="#" className="flex w-48 justify-center text-sky-900 text-xs font-bold font-['Myriad Pro'] px-3 py-3 text-blue-900 hover:text-white hover:bg-sky-900">Press Release</Link>
                            </li>
                            <li>
                                <Link to="#" className="flex w-48 justify-center text-sky-900 text-xs font-bold font-['Myriad Pro'] px-3 py-3 text-blue-900 hover:text-white hover:bg-sky-900">Media Coverage</Link>
                            </li>
                        </ul>
                        )}
                    </li> */}
                    <li onMouseEnter={toggleDropdown5} onMouseLeave={toggleDropdown5} className="relative">
                        <Link to="/Careers" className="flex w-48 basis-0 justify-center text-sky-900 text-xs font-bold font-['Myriad Pro'] uppercase px-3 py-3 text-blue-900 hover:text-white hover:bg-sky-900">
                            <Trans i18nKey="navigation.careers" />
                        </Link>
                        {showDropdown5 && (
                            <ul className="absolute top-full left-0 z-10 bg-white"></ul>
                        )}
                    </li>
                </ul>
            </nav>
            <div className="text-center md:hidden w-full">
                <button
                    className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                    onClick={() => setNavbar(!navbar)}
                >
                    {navbar ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fillRule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    )}
                </button>
            </div>
            <div>
                <div
                    className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
                        navbar ? "block" : "hidden"
                    }`}
                >
                    <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0 md:hidden">
                        <li className="text-gray-600 text-center hover:text-blue-600">
                            <Link to="/WhoWeAre"><Trans i18nKey="navigation.whoweare" /></Link>
                        </li>
                        <li className="text-gray-600 text-center hover:text-blue-600">
                            <Link to="/WhatWeDo"><Trans i18nKey="navigation.whatwedo" /></Link>
                        </li>
                        <li className="text-gray-600 text-center hover:text-blue-600">
                            <Link to="/CustomerServices"><Trans i18nKey="navigation.cs" /></Link>
                        </li>
                        {/* <li className="text-gray-600 text-center hover:text-blue-600">
                            <Link to="/MediaCenter">MEDIA CENTER</Link>
                        </li> */}
                        <li className="text-gray-600 text-center hover:text-blue-600">
                            <Link to="/Careers"><Trans i18nKey="navigation.careers" /></Link>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
        <AnimatePresence>
        <Routes>
            <Route path="/" element={<WhoWeAre />} exact />
            <Route path="/whoweare" element={<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}><WhoWeAre /></motion.div>} />
            <Route path="/whatwedo" element={<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}><WhatWeDo /></motion.div>} />
            <Route path="/customerservices" element={<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}><CustomerServices /></motion.div>} />
            <Route path="/mediacenter" element={<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}><MediaCenter /></motion.div>} />
            <Route path="/careers" element={<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}><Careers /></motion.div>} />
        </Routes>
        </AnimatePresence>
        </Router>
        
    )
}

export default Header;