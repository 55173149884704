import React from "react";
import { useTranslation, Trans } from 'react-i18next';
import MainPageBannerImage from './assets/img/MainPage_MainPageBanner.png';


const Home = () => {
    const { t } = useTranslation();
    return (
        <main className="flex-grow relative page">
            <img
            src={MainPageBannerImage}
            alt="Banner"
            className="w-full"
            id="MainPageBannerImage"
            style={{ height: '669px' }}
            />
            <div className="VictoryAssetManagementJapan absolute text-white font-normal font-['Myriad Pro'] uppercase top-40 left-10 text-5xl lg:text-8xl"><Trans i18nKey="home.title" /></div>
        </main>
    )
}

export default Home;