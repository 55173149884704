import React from "react";
import { useTranslation, Trans } from 'react-i18next';
import MainBannerImage from "./assets/img/c_main.png";
import image1 from './assets/img/careers_image.png';

 
const Careers = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="page">
      <main className="flex-grow relative">
        <div className="w-full bg-center bg-cover bg-no-repeat" style={{ backgroundImage: `url(${MainBannerImage})`, height: '500px' }}></div>
        <div className="absolute text-white font-normal font-['Myriad Pro'] uppercase top-40 left-10 text-5xl lg:text-8xl"><Trans i18nKey="careers.title" /></div>
      </main>
      <div className="w-full mx-auto my-auto">
        <div className="p-10"></div>
        <div className="flex">
          <div className="mx-auto">
            <div className="p-5 max-w-1280 text-center text-sky-900 text-xl font-semibold font-['Myriad Pro']"><Trans i18nKey="careers.description" /></div>
          </div>
        </div>
        <div className="p-5"></div>
        <div className="grid md:grid-cols-2 flex justify-center">
          <div className="col-span-1 p-5 mx-auto"><img src={image1} alt="careers" /></div>
          <div className="col-span-1 p-5 mx-auto my-auto text-center">
            <div className="text-sky-900 text-4xl font-normal font-['Myriad Pro'] uppercase p-2"><Trans i18nKey="careers.subtitle" /></div>
            <div className="text-sky-900 text-xl font-normal font-['Myriad Pro'] p-2"><Trans i18nKey="careers.description2" /></div>
            <div className="w-56 h-11 p-2.5 bg-sky-900 rounded-lg flex-col justify-center items-center gap-2.5 inline-flex m-5">
              <div className="justify-start items-start gap-7 inline-flex">
                <a href="mailto:general_inquiry@victoryam.jp">
                  <div className="SendNow text-center text-white text-xl font-bold font-['Myriad Pro'] uppercase leading-loose"><Trans i18nKey="careers.button_text" /></div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="p-5"></div>
      </div>
    </div>
  );
}

 
export default Careers;